<template>
  <div class="company" v-loading="loading">
    <div class="man-title">公司管理
      <el-button @click="add" class="btn" type="primary" size="mini">新增公司</el-button>
    </div>
    <div class="list-box">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="名称" width="240"></el-table-column>
        <el-table-column prop="addr" label="地址"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-dropdown placement="bottom-start" trigger="click">
              <span @click.stop="">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down"></i>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="detail(scope.row)">详情</div></el-dropdown-item>
                <el-dropdown-item><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                <el-dropdown-item><div @click="generate(scope.row)">生成巡点检</div></el-dropdown-item>
                <el-dropdown-item><div @click="init(scope.row)">初始化巡点检</div></el-dropdown-item>
                <el-dropdown-item><div @click="generateLube(scope.row)">生成润滑</div></el-dropdown-item>
                <el-dropdown-item><div @click="initLube(scope.row)">初始化润滑</div></el-dropdown-item>
                <el-dropdown-item><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="生成润滑"
      :visible.sync="dialogVisible"
      width="30%">
      <el-input v-model="todayZero" placeholder="输入开始时间" style="margin-bottom:20px;"></el-input>
      <el-input v-model="tomorrowZero" placeholder="输入结束时间"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirms" :disabled="isShow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Page from '@/components/page'
export default {
  components: {
    Page
  },
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      todayZero: '',
      tomorrowZero: '',
      isShow: false,
    }
  },
  methods: {
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('companyDelete', {
          id: row.id
        }).then(res => {
          this.loadList()
        })
      })
    },
    detail(row) {
      this.$router.push({ name: 'companyDetail', params: {row: row}})
    },
    edit(row) {
      this.$router.push({ name: 'companyEdit', params: row})
    },
    add() {
      this.$router.push({name: 'companyAdd'})
    },
    generate() {
      this.loading = true
      this.$ajax.get('generate').then(res => {
        if(res.code == 0) {
          this.loading = false
        }else {
          this.loading = false
        }
      }).catch(err=>{
        this.loading = false
      })
    },
    init() {
      this.$ajax.get('init').then(res => {})
    },
    generateLube(row) {
      this.dialogVisible = true
    },
    confirms() {
      this.isShow = true
      this.$ajax.post('generateLube', {
        todayZero: parseInt(this.todayZero),
        tomorrowZero: parseInt(this.tomorrowZero)
      }).then(res => {
        if(res.code == 0) {
          this.loadList()
          this.dialogVisible = false
          this.isShow = false
        }else {
          this.dialogVisible = false
          this.isShow = false
        }
      }).catch(err=>{
        this.dialogVisible = false
        this.isShow = false
      })
    },
    initLube() {
      this.loading = true
      this.$ajax.get('initLube').then(res => {
        if(res.code == 0) {
          this.loading = false
        }else {
          this.loading = false
        }
      }).catch(err=>{
        this.loading = false
      })
    },
    loadList() {
      this.$ajax.get('companyQueryAll').then(res => {
        this.tableData = res.data
      })
    }
  },
  mounted() {
    this.loadList()
  }
}
</script>

<style lang="less" scoped>
.company {
  .btn {
    float: right;
  }
}
</style>